import './PdfMerger.css';
import {Button} from "@mui/material";
import * as React from 'react';
import {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import downloadBojoFile from "./common";

function PdfMerger() {

    const [files, setFiles] = useState([// {name: 'bla bla JN lön.pdf'},
        // {name: 'sdf 222 er vdf.pdf'},
        // {name: 'sdf 3333 er vdf.pdf'},
        // {name: 'sdf 4444 er vdf.pdf'},
    ]);

    function fetchPdf() {

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        files.forEach((file, i) => {
            formData.append(`file${i + 1}`, file);
        });

        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/pdf'
            },
            body: formData
        };

        downloadBojoFile("/pdf-merge",  requestOptions);
    }

    function moveFile(file, moveUp) {

        const index = files.map(file => file.name).indexOf(file.name);
        if (index > -1) {
            const newFiles = [...files];
            const movedFile = newFiles.splice(index, 1)[0];
            let moved = false;
            if (moveUp && index > 0) {
                newFiles.splice(index - 1, 0, movedFile);
                moved = true;
            } else if (!moveUp && index < files.length - 1) {
                newFiles.splice(index + 1, 0, movedFile);
                moved = true;
            }

            if (moved) {
                setFiles(newFiles);
            }
        }
    }

    function removeFile(file) {

        const index = files.map(file => file.name).indexOf(file.name);
        if (index > -1) {

            console.log('index', index);
            const newFiles = [...files];
            newFiles.splice(index, 1);
            setFiles(newFiles);

            console.log('newFiles', newFiles);
        }

        console.log('list', files);
    }

    const filesBlock = files.map((file, index) => {
        return (<div className="file-container" key={index}>

            <FontAwesomeIcon icon="arrow-up" onClick={() => moveFile(file, true)}/>
            <FontAwesomeIcon icon="arrow-down" onClick={() => moveFile(file, false)}/>

            <div>{file.name}</div>

            <FontAwesomeIcon icon="trash-alt" onClick={() => removeFile(file)}/>
        </div>);
    });

    function onFileSelection(event) {

        setFiles([...files, ...event.target.files]);
    }

    return (<div className="PdfMerger">

        <div className="file-upload-container">
            <label htmlFor="file-upload">
                <FontAwesomeIcon icon="file-upload"/>
            </label>
            <input
                id="file-upload"
                style={{display: 'none'}}
                type="file"
                multiple={true}
                onChange={onFileSelection}
            />
        </div>

        <div className="file-list-container">
            {filesBlock}
        </div>

        <div className="row">
            <Button variant="contained" onClick={fetchPdf}>Skapa PDF</Button>
        </div>
    </div>);
}

export default PdfMerger;
